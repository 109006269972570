import React from 'react';
import { Box, Container, Stack, Link, IconButton, Text, Divider, Flex } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaArrowUp } from 'react-icons/fa';


const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling
        });
    };

    return (
        <Box bg="black" color="white" py={10}>
            <Container maxW="container.xl">
                <Stack direction={{ base: 'column', md: 'row' }} spacing={8} justify="space-between" align="center">
                    <Stack direction="row" spacing={6}>
                        <Link href="#" isExternal>
                            <IconButton
                                variant="ghost"
                                color="white"
                                aria-label="Facebook"
                                icon={<FaFacebook />}
                                _hover={{ color: 'orange.400' }}
                            />
                        </Link>
                        <Link href="#" isExternal>
                            <IconButton
                                variant="ghost"
                                color="white"
                                aria-label="Instagram"
                                icon={<FaInstagram />}
                                _hover={{ color: 'orange.400' }}
                            />
                        </Link>
                        <Link href="#" isExternal>
                            <IconButton
                                variant="ghost"
                                color="white"
                                aria-label="LinkedIn"
                                icon={<FaLinkedin />}
                                _hover={{ color: 'orange.400' }}
                            />
                        </Link>
                        <Link href="#" isExternal>
                            <IconButton
                                variant="ghost"
                                color="white"
                                aria-label="YouTube"
                                icon={<FaYoutube />}
                                _hover={{ color: 'orange.400' }}
                            />
                        </Link>
                    </Stack>

                    <Flex>
                        <IconButton
                            aria-label="Scroll to top"
                            icon={<FaArrowUp />}
                            variant="solid"
                            colorScheme="blue"
                            onClick={scrollToTop}
                            _hover={{ bg: "#003285" }}
                        />
                    </Flex>
                </Stack>

                <Divider my={6} borderColor="gray.600" />

                <Flex direction={{ base: 'column', md: 'row' }} justify="space-between">
                    <Stack spacing={6}>
                        <Text>Catégories</Text>
                        <Link href="#">Informatique</Link>
                        <Link href="#">Electroménager</Link>
                        <Link href="#">Smartphones</Link>
                        <Link href="#">Image et Son</Link>
                        <Link href="#">Photo et Caméra</Link>
                    </Stack>

                    <Stack spacing={6}>
                        <Text>Informations</Text>
                        <Link target="_blank"  href="https://www.graiet.tn/qui-somme-nous">À propos de nous</Link>
                        <Link target="_blank"  href="https://www.graiet.tn/contact">Contact</Link>
                    </Stack>

                    <Stack spacing={6}>
                        <Text>Sites web comparés</Text>
                        <Link target="_blank" href="https://www.mytek.tn/">Mytek</Link>
                        <Link target="_blank" href="https://www.jumia.com.tn/">Jumia</Link>
                        <Link target="_blank" href="https://www.tunisianet.com.tn/">Tunisianet</Link>
                        <Link target="_blank" href="https://spacenet.tn/">Spacenet</Link>
                    </Stack>
                </Flex>

                <Divider my={6} borderColor="gray.600" />

                <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
                    <Text>© Graiet 2024</Text>
                    <Stack direction="row" spacing={4}>
                        <Link href="#">Informations légales</Link>
                        <Link href="#">Conditions générales</Link>
                        <Link href="https://www.graiet.tn/qui-somme-nous" target='_blank'>Fondation Graiet</Link>
                        <Link href="#">Carrières</Link>
                        <Link href="#">Actualités</Link>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default Footer;
