





import React, { useRef,useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from './Components/Footer';

import {
  ChakraProvider,
  Box,
  Container,
  IconButton,
  Text,
  SimpleGrid,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
  Button,
  VStack,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react';

import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
// Load environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SIMILARITY_API_BASE_URL = process.env.REACT_APP_SIMILARITY_API_BASE_URL;
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

const Admin = () => {
  const similarProductsRef = useRef(null);

  const [mytekRef, setMytekRef] = useState('');
  const [spacenetRef, setSpacenetRef] = useState('');
  const [tunisianetRef, setTunisianetRef] = useState('');
  const [jumiaRef, setJumiaRef] = useState('');
  const [graietSku, setGraietSku] = useState('');
  const toast = useToast();
  const [products, setProducts] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [searchSku, setSearchSku] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [highlightedSkus, setHighlightedSkus] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
const cancelRef = React.useRef();


  const handle_adjustment_by_input = async () => {
    const adjustmentData = {
      mytekRef,
      spacenetRef,
      tunisianetRef,
      jumiaRef,
      graietSku,
    };
  
    try {
      const response = await axios.post(`${API_BASE_URL}/update-adjusted-reference-by-input`, adjustmentData);


  
      const result = response.data;
  
      // Check if the response contains a message indicating references were not found
      if (response.status === 404) {
        // Show error toast with the references not found message
        toast({
          title: 'Reference Not Found',
          description: result.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Show success toast
        toast({
          title: 'Success',
          description: result.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
  
      console.log('Adjustment response:', result);
    } catch (error) {
      console.error('Error making adjustment:', error);
  
      // Show error toast for network errors
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Network error. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  const fetchProducts = useCallback(async (page = 1) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/products`, { params: { page, limit: perPage } });

      setProducts(response.data.products);
      setTotalRows(response.data.total);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [perPage]);

  const handlePageChange = (page) => {
    fetchProducts(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchProducts(page);
  };

  const handleFetchSimilarProducts = async (sourceSku) => {
    setLoading(true);
    try {
      const [resMytek, resTunisianet, resSpacenet] = await Promise.all([
        axios.get(`${SIMILARITY_API_BASE_URL}/graiet/mytek`).catch((error) => {
          console.error("Error fetching Mytek data:", error);
          return { data: [] }; // Return empty data to prevent undefined errors
        }),
        axios.get(`${SIMILARITY_API_BASE_URL}/graiet/tunisianet`).catch((error) => {
          console.error("Error fetching Tunisianet data:", error);
          return { data: [] };
        }),
        axios.get(`${SIMILARITY_API_BASE_URL}/graiet/spacenet`).catch((error) => {
          console.error("Error fetching Spacenet data:", error);
          return { data: [] };
        }),
      ]);
  
      const allResults = [
        ...resMytek.data.filter((item) => item.source_sku === sourceSku).map((item) => ({ ...item, website: 'Mytek' })),
        ...resTunisianet.data.filter((item) => item.source_sku === sourceSku).map((item) => ({ ...item, website: 'Tunisianet' })),
        ...resSpacenet.data.filter((item) => item.source_sku === sourceSku).map((item) => ({ ...item, website: 'Spacenet' })),
      ];
  
      setSimilarProducts(allResults);
  
      if (allResults.length > 0) {
        // Scroll to the similar products section
        similarProductsRef.current.scrollIntoView({ behavior: 'smooth' });
        toast({
          title: 'Produits similaires trouvés',
          description: `Défiler vers le bas pour voir les produits similaires au produit Graiet avec la référence : ${sourceSku}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Pas de produits similaires',
          description: `Pas de produits similaires pour le produit avec la référence Graiet : ${sourceSku}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error fetching similar products:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch similar products.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  

  const handleSearchChange = (event) => {
    const sku = event.target.value;
    setSearchSku(sku);
    if (sku.trim() !== '') {
      handleFetchSimilarProducts(sku);
    } else {
      setSimilarProducts([]); // clear similar products if search is empty
    }
  };

  const handleCheckboxChange = (result) => {
    setSelectedProducts((prev) => {
      if (prev.includes(result)) {
        return prev.filter((item) => item !== result);
      } else {
        return [...prev, result];
      }
    });
  };

  const handleSave = () => {
    if (selectedProducts.length === 0) {
      toast({
        title: 'No products selected',
        description: 'Please select products to save.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    onOpen(); 
  };
  
  const confirmSave = async () => {
    const targetWebsite = selectedProducts[0].website.toLowerCase();
    try {
      await axios.post(`${API_BASE_URL}/update-adjusted-reference`, {
        target_website: targetWebsite,
        selectedProducts: selectedProducts.map((product) => ({
          source_sku: product.source_sku,
          target_reference: product.target_reference || null,
        })),
      });
  
      await Promise.all(
        selectedProducts.map((product) =>
          axios.post(`${API_BASE_URL}/update-status`, { product_graiet_reference: product.source_sku })
        )
      );
  
      setHighlightedSkus((prev) => [...prev, ...selectedProducts.map((p) => p.source_sku)]);
      toast({
        title: 'Success',
        description: 'Adjusted references updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
       console.log('Adjusted references updated successfully for selected products');
    } catch (error) {
      console.error(`Error updating adjusted reference or status for selected products`, error);
      toast({
        title: 'Error',
        description: 'Failed to update adjusted references.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onClose(); // Close confirmation modal
    }
  };
  

  useEffect(() => {
    fetchProducts(1);
  }, [fetchProducts]);

  const columns = [
    {
      name: 'SKU',
      selector: (row) => row.sku,
      sortable: true,
      cell: (row) => <Box bg={row.adjustment_status ? 'green.200' : 'transparent'}>{row.sku}</Box>,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <Box bg={row.adjustment_status ? 'green.200' : 'transparent'}>{row.name}</Box>,
    },
    {
      name: 'Price',
      selector: (row) => row.price,
      sortable: true,
      cell: (row) => <Box bg={row.adjustment_status ? 'green.200' : 'transparent'}>{row.price}</Box>,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <IconButton
          icon={<ChevronDownIcon />}
          onClick={() => {
            setGraietSku(row.sku); // Set the Graiet SKU input with the selected product's SKU
            handleFetchSimilarProducts(row.sku); // Fetch similar products if needed
          }}
        />
      ),
    },
  ];

  return (
    <ChakraProvider>
      <Container maxW="container.xl">
        <Box mb={4}>
          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            Product List
          </Text>
          <InputGroup>
            <Input placeholder="Search by SKU" value={searchSku} onChange={handleSearchChange} />
            <InputRightElement>
              <IconButton icon={<SearchIcon />} />
            </InputRightElement>
          </InputGroup>
        </Box>
        <DataTable
          columns={columns}
          data={products}
          customStyles={customStyles}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressPending={loading}
        />
        {similarProducts.length > 0 && (
          <Box mt={4} ref={similarProductsRef}>
            <Text fontSize="xl" fontWeight="bold" mb={2}>
              Similar Products
            </Text>
            <SimpleGrid columns={1} spacing={4}>
              {similarProducts.map((result, index) => (
                <Box
                  key={index}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  bg={selectedProducts.includes(result) ? 'green.100' : 'white'}
                >
                  <Checkbox isChecked={selectedProducts.includes(result)} onChange={() => handleCheckboxChange(result)}>
                    <VStack align="start">
                      <Text><strong>Website:</strong> {result.website}</Text>
                      <Text><strong>Source Product:</strong> {result.source_product}</Text>
                      <Text><strong>Source SKU:</strong> {result.source_sku}</Text>
                      <Text><strong>Source Price:</strong> {result.source_price}</Text>
                      <Text><strong>Target Product:</strong> {result.target_product}</Text>
                      <Text><strong>Target Reference:</strong> {result.target_reference || 'N/A'}</Text>
                      <Text><strong>Target Price:</strong> {result.target_price}</Text>
                      <Text><strong>Target Remise:</strong> {result.target_remise}</Text>
                      <Text><strong>Similarity Score:</strong> {result.similarity_score}</Text>
                    </VStack>
                  </Checkbox>
                </Box>
              ))}
            </SimpleGrid>
            <Box mt={4}>
              <Button colorScheme="blue" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        )}
        
        <Box mt={8}>
          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            Adjustment by Input
          </Text>
          <SimpleGrid columns={2} spacing={4}>
            <Input placeholder="Mytek Reference" value={mytekRef} onChange={(e) => setMytekRef(e.target.value)} />
            <Input placeholder="Spacenet Reference" value={spacenetRef} onChange={(e) => setSpacenetRef(e.target.value)} />
            <Input placeholder="Tunisianet Reference" value={tunisianetRef} onChange={(e) => setTunisianetRef(e.target.value)} />
            <Input placeholder="Jumia Reference" value={jumiaRef} onChange={(e) => setJumiaRef(e.target.value)} />
            <Input placeholder="Graiet SKU" value={graietSku} onChange={(e) => setGraietSku(e.target.value)} />
            <Button colorScheme="blue" onClick={handle_adjustment_by_input}>
              Update Adjusted Reference
            </Button>
          </SimpleGrid>
        </Box>
        <AlertDialog
  isOpen={isOpen}
  leastDestructiveRef={cancelRef}
  onClose={onClose}
>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Confirm Save
      </AlertDialogHeader>

      <AlertDialogBody>
        Are you sure you want to save these selected products?
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={onClose}>
          Cancel
        </Button>
        <Button colorScheme="blue" onClick={confirmSave} ml={3}>
          Confirm
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>

      </Container>
      <Box mt={8}>
<Footer/>
</Box>
    </ChakraProvider>
  );
};

export default Admin;
